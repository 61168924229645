import axios from "axios";
import domain from "../util/domain";

axios.defaults.withCredentials = true;

const PATH = domain;

// Event APIS
export const postEvent = async (newEvent) => {
  return axios.post(PATH + "/events", newEvent);
};

export const putEvent = async ({ id, updatedEvent }) => {
  return axios.put(PATH + "/events/" + id, updatedEvent);
};

export const getEvent = async (id) => {
  const res = await axios.get(PATH + "/events/" + id);
  return res.data;
};

export const getEvents = async (displayName) => {
  const res = await axios.get(PATH + "/events/public/" + displayName);

  return res.data;
};

// Blog APIS
export const postBlog = async (newBlog) => {
  return axios.post(PATH + "/blogs", newBlog);
};

export const getBlog = async (id) => {
  const res = await axios.get(PATH + "/blogs/" + id);
  return res.data;
};

export const getBlogs = async (displayName) => {
  const res = await axios.get(PATH + "/blogs/public/" + displayName);

  return res.data;
};

// Image APIS
export const uploadImage = async (bodyFormData) => {
  const response = await axios.post(PATH + "/image/upload", bodyFormData);
  return response.data;
};

// User APIS
export const logOutUser = async () => {
  return axios.get(PATH + "/auth/logOut");
};

export const getLoggedInUser = async () => {
  return axios.get(PATH + "/auth/loggedIn");
};

export const logInUser = async (loginFormBody) => {
  return axios.post(PATH + "/auth/login", loginFormBody);
};

export const signUpUser = async (signupFormBody) => {
  return axios.post(PATH + "/auth/signup", signupFormBody);
};

export const sendForgotPasswordEmailToUser = async (emailFormBody) => {
  return await axios.post(PATH + "/auth/forgotPassword", emailFormBody);
};

export const resetPassword = async (resetPasswordFormBody, params) => {
  return await axios.post(PATH + "/auth/resetPassword", resetPasswordFormBody, {
    params: params,
  });
};

// Trips APIs
export const getAllTripsForUser = async () => {
  return await axios.get(PATH + "/trips/allTripsForUser");
};

export const createTripForUser = async (createTripFormBody) => {
  return await axios.post(PATH + "/trips", createTripFormBody);
};

export const getTripById = (tripId) => {
  return axios.get(PATH + "/trips/" + tripId);
};

export const updateTripForUser = async ({ tripId, updateTripFormBody }) => {
  return await axios.put(PATH + "/trips/" + tripId, updateTripFormBody);
};
